export const socialUrls = ["https://www.facebook.com", "https://github.com", "https://youtube.com"];

export const columns = 3;

export const blogContent = [
  {
    imageUrl: "https://placekitten.com/408/287",
    header: "Learn the right things, in the right order",
    body: "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia.",
    articleUrl: "#",
    articleTitle: "HTML article",
    articleTime: "20 minutes"
  },
  {
    imageUrl: "https://placekitten.com/408/287",
    header: "Learn the right things, in the right order",
    body: "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia.",
    articleUrl: "#",
    articleTitle: "HTML article",
    articleTime: "20 minutes"
  },
  {
    imageUrl: "https://placekitten.com/408/287",
    header: "Learn the right things, in the right order",
    body: "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia.",
    articleUrl: "#",
    articleTitle: "HTML article",
    articleTime: "20 minutes"
  },
  {
    imageUrl: "https://placekitten.com/408/287",
    header: "Learn the right things, in the right order",
    body: "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia.",
    articleUrl: "#",
    articleTitle: "HTML article",
    articleTime: "20 minutes"
  },
  {
    imageUrl: "https://placekitten.com/408/287",
    header: "Learn the right things, in the right order",
    body: "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia.",
    articleUrl: "#",
    articleTitle: "HTML article",
    articleTime: "20 minutes"
  },
  {
    imageUrl: "https://placekitten.com/408/287",
    header: "Learn the right things, in the right order",
    body: "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia.",
    articleUrl: "#",
    articleTitle: "HTML article",
    articleTime: "20 minutes"
  },
];

export const tags = ["About Launch Academy", "Stories", "Learn to code", "Uncategorized"]



